import "../styles/globals.css";
import "@formatjs/intl-locale/polyfill"; // A polyfill for Intl.Locale constructor
import "@formatjs/intl-locale/polyfill"; // A polyfill for Intl.Locale constructor
import type { AppProps } from "next/app";
import CartProvider from "../context/cart";
import { IntlProvider } from "next-intl";
import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import DraftOrderProvider from "src/context/draftOrder";
import PaymentProvider from "src/context/payment";
import UserInformationProvider from "src/context/CheckoutUserInformation";
import CreditCardProcessingProvider from "src/context/CreditCardProcessingContext";
import NextNProgress from "nextjs-progressbar";
import EventTracker from "src/helpers/event-tracking/eventTrackingManager";
import StoreMetadataProvider from "src/context/StoreMetadataContext";
import setCookie from "src/helpers/cookie/setCookie";
import ErrorBoundary from "src/components/error-boundary/ErrorBoundary";
import BillingAddressProvider from "src/context/billingAddress";
import Cookies from "js-cookie";
import getMessageFallback from "src/helpers/localization-helpers/getMessageFallback";
import consts from "src/config/consts";
import GoogleAnalytics from "src/components/google-analytics/googleAnalytics";
import useCountryCode from "src/hooks/useCountryCode";
import useLanguage from "src/hooks/useLanguage";
import TabbyProcessingProvider from "src/context/TabbyProcessingContext";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import ShopifyCheckoutProvider from "src/context/ShopifyCheckout";
import ReferralProvider from "src/context/ReferralContext";
import { AuthenticationProvider } from "src/context/authentication";
import CreditsProvider from "src/context/CreditsContext";
import { NotificationProvider } from "src/context/notification";
import useRtl from "src/hooks/useRtl";
import BundleDiscountProvider from "src/context/BundleDiscountContext";
import SnapChatPixel from "src/components/snapchat-pixel/snapchat-pixel";
import onError from "src/helpers/localization-helpers/onError";
import TiktokPixel from "src/components/tiktok-pixel/TikTokPixel";
import SetProvider from "src/context/SetContext";
import { GrowthbookClientProvider } from "src/context/GrowthbookClientContext";
import Script from "next/script";
import NewPriceCollectionProvider from "src/context/NewPriceCollectionContext";
import ConversionSourceProvider from "src/context/ConversionSourceContext";
import { HighlightInit } from "@highlight-run/next/client";
import { install as installResizeObserver } from "resize-observer";
import { StoreProvider, createStore, persist } from "easy-peasy";
import { StoreModel } from "src/types/store/StoreModel";
import storeModel from "src/store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { omit } from "lodash";

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const { storeMetadata, newPriceCollection, translations } = pageProps;

  const countryCode = useCountryCode();
  const isRtl = useRtl();
  const router = useRouter();

  useEffect(() => {
    if (countryCode) {
      setLocalStorage("country_code", countryCode);
    }
  }, [countryCode]);

  const language = useLanguage();

  const effectTriggeredRef = useRef(false);

  const { pathname, query, locale } = useRouter();
  useEffect(() => {
    if (effectTriggeredRef.current === true) {
      EventTracker.pageViewed();
    }
  }, [pathname]);

  useEffect(() => {
    if (!effectTriggeredRef.current) {
      effectTriggeredRef.current = true;
      EventTracker.visit();
      EventTracker.pageViewed();
    }
  }, []);

  /**
   * Set selected language cookie once the page loads
   */
  useEffect(function setSelectedLanguageCookie() {
    Cookies.set("selected_language", language.value);
  }, []);

  useEffect(() => {
    if (query?.fbclid) setCookie("fbclid", query?.fbclid, 30);
    if (query?.ScCid) setLocalStorage("ScCid", query?.ScCid);
    if (query?.ttclid) setLocalStorage("ttclid", query?.ttclid);
  }, [query]);

  /**
   * Prevents Safari from caching the website
   */
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  /**
   * Add ResizeObserver polyfill for Safari
   */
  useEffect(() => {
    if (!window.ResizeObserver) installResizeObserver();
  }, []);

  // Koala logic
  const store = createStore<StoreModel>(persist(storeModel, { storage: "localStorage" }), {
    initialState: omit(pageProps, ["translations", "oms", "storeMetadata", "isCartSidebarExpanded"]) as any,
  });

  return (
    <div dir={isRtl ? "rtl" : "ltr"} className="notranslate" translate="no">
      {/* A polyfill for global this for older browsers
          https://github.com/vercel/next.js/discussions/49771
      */}
      <Script strategy="beforeInteractive" src="https://unpkg.com/@ungap/global-this@0.4.4/min.js" />
      <IntlProvider
        locale={locale ?? consts.DEFAULT_LOCALE}
        messages={translations}
        formats={{
          dateTime: {
            short: {
              day: "numeric",
              month: "short",
              year: "numeric",
            },
          },
        }}
        onError={(error) => onError(error, router)}
        getMessageFallback={getMessageFallback}
      >
        <StoreProvider store={store}>
          <GrowthbookClientProvider>
            <NotificationProvider>
              <AuthenticationProvider>
                <SnapChatPixel />
                <GoogleAnalytics />
                <TiktokPixel />
                <StoreMetadataProvider storeMetadata={storeMetadata}>
                  <DraftOrderProvider>
                    <CreditsProvider>
                      <UserInformationProvider>
                        <CreditCardProcessingProvider>
                          <TabbyProcessingProvider>
                            <SetProvider>
                              <CartProvider>
                                <BundleDiscountProvider>
                                  <NewPriceCollectionProvider newPriceCollection={newPriceCollection}>
                                    <ReferralProvider>
                                      <ShopifyCheckoutProvider>
                                        <PaymentProvider>
                                          <BillingAddressProvider>
                                            <ConversionSourceProvider>
                                              <NextNProgress
                                                options={{ showSpinner: false }}
                                                height={3}
                                                color={"#9a5638"}
                                              />
                                              <ErrorBoundary>
                                                {process.env.NEXT_PUBLIC_ENV === "production" &&
                                                  typeof window != "undefined" && (
                                                    <HighlightInit
                                                      projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
                                                      environment={process.env.NEXT_PUBLIC_ENV}
                                                      serviceName="shemsi"
                                                      tracingOrigins
                                                      networkRecording={{
                                                        enabled: process.env.NEXT_PUBLIC_ENV === "production",
                                                        recordHeadersAndBody: true,
                                                        urlBlocklist: [],
                                                      }}
                                                    />
                                                  )}
                                                <ToastContainer />
                                                <Component {...pageProps} />
                                              </ErrorBoundary>
                                            </ConversionSourceProvider>
                                          </BillingAddressProvider>
                                        </PaymentProvider>
                                      </ShopifyCheckoutProvider>
                                    </ReferralProvider>
                                  </NewPriceCollectionProvider>
                                </BundleDiscountProvider>
                              </CartProvider>
                            </SetProvider>
                          </TabbyProcessingProvider>
                        </CreditCardProcessingProvider>
                      </UserInformationProvider>
                    </CreditsProvider>
                  </DraftOrderProvider>
                </StoreMetadataProvider>
              </AuthenticationProvider>
            </NotificationProvider>
          </GrowthbookClientProvider>
        </StoreProvider>
      </IntlProvider>
    </div>
  );
}

export default MyApp;
