import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { creditsConfig } from "../credits.config";
import { CountryName } from "src/types/localization/CountryName";
import { CountryCode } from "src/types/localization/CountryCode";
import { Currency } from "src/types/localization/Currency";
import { LanguageName } from "src/types/localization/LanguageName";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { bundleDiscountSystems } from "../bundle/defaultBundleDiscountSystems";
import consts from "../consts";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_MA ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_MA ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_MA ?? "",
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 300042715296,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 300042584224,
    name: "all_single",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 300042748064,
    name: "packs",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 300042715296,
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 300042715296,
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 300042715296,
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 0,
    name: "gifts",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 300042715296,
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "66614b58d9470fe2c0ea8903" : "664f51630a3fd8204e8bb01e",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction"].includes(env) ? "66614c41d9470fe2c0ea8af4" : "6659ee0e7d16c96d87f57e6b",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: "",
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: "",
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: "",
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "35.00",
  },
  fees: 35,
  freeLimit: 750,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+971585096963",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840,
      7075133456520,

      // Prod
      7933561700579,

      // Prod packs
      7937883013347,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,

      7641209897120, // pack prod morocco
      7641209602208, // single prod morocco
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256,
      7075128737928,

      // Prod
      7937881014499,

      // Prod packs
      7937882947811,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,

      7641209700512, // single prod morocco
      7641209831584, // pack prod morocco
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      7937880948963,

      // Prod pack alexandria
      7937882915043,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,

      // alex pack prod morocco
      7641209766048,

      // alex single morocco
      7641209667744,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616,
      7060449493128,

      // Prod
      7937881112803,

      // Prod pack
      7937882980579,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,

      7641209733280, // havana single prod morocco
      7641209864352, // havana pack prod morocco
    ],
    ratings: 9500,
  },
];

const MA = new Country({
  name: CountryName.Morocco,
  code: CountryCode.MA,
  currency: Currency.MAD,
  numberOfCents: 100,
  region: "MENA",
  phoneExample: "0660846184",
  provinces: [],
  provincyRequiredInCheckout: false,
  zipRequiredInCheckout: true,
  supportedLanguages: [
    {
      label: LanguageName.العربية,
      value: LanguageCode.ar,
    },
    {
      label: LanguageName.English,
      value: LanguageCode.en,
    },
    {
      label: LanguageName.Francais,
      value: LanguageCode.fr,
      default: true,
    },
  ],
  shopifyKeys,
  omsConnector: "shopify-morocco",
  collections,
  koalaCollections,
  checkoutMethod: "in-house",
  shipping,
  productsData,
  freeShipping: true,
  socialInfo,
  creditsConfig,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  paymentMethods: [SupportedPaymentMethod.cashOnDelivery, SupportedPaymentMethod.creditCard],
  bundleDiscountSystems,
  splitTestDiscount: {
    discountAmount: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE,
    discountType: "percentage",
  },
  hasPricesAbTest: false,
  christmasTheme: false,
  multiCategory: false,
  multiColors: false,
  pargoPickupPoints: false,
  hasGiftCards: false,
  hasAccessories: false,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: false,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 7937881014499
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  // oms: ["production", "preproduction", "ci"].includes(env) ? "shopify" : "koala",
  oms: "koala",
  orderScopes: ["fulfil", "nps_free", "referral"],
  pl3Connector: "chrono-morocco",
});

export default MA;
